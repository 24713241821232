/** @format */

import { css } from 'styled-components';

export const BREAKPOINTS = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1440px',
};

export const device = {
  mobile: `(min-width: ${BREAKPOINTS.sm})`,
  tablet: `(min-width: ${BREAKPOINTS.md})`,
  tabletlg: `(min-width: ${BREAKPOINTS.lg})`,
  laptop: `(min-width: ${BREAKPOINTS.xl})`,
  desktop: `(min-width: ${BREAKPOINTS.xxl})`,
};

type cssParams = Parameters<typeof css>;
const keys = Object.keys(BREAKPOINTS) as Array<keyof typeof BREAKPOINTS>;

export const breakpointFrom = keys.reduce((accumulator, label) => {
  accumulator[label] = (...args: cssParams) => {
    return css`
      @media (min-width: ${BREAKPOINTS[label]}) {
        ${css(...args)};
      }
    `;
  };
  return accumulator;
}, {} as Record<keyof typeof BREAKPOINTS, Function>);

export const breakpointUpTo = keys.reduce((accumulator, label) => {
  accumulator[label] = (...args: cssParams) => {
    return css`
      @media (max-width: calc(${BREAKPOINTS[label]} - 1px)) {
        ${css(...args)};
      }
    `;
  };
  return accumulator;
}, {} as Record<keyof typeof BREAKPOINTS, Function>);

export const breakpointFromExclude = keys.reduce((accumulator, label) => {
  accumulator[label] = (...args: cssParams) => {
    return css`
      @media (min-width: calc(${BREAKPOINTS[label]} + 1px)) {
        ${css(...args)};
      }
    `;
  };
  return accumulator;
}, {} as Record<keyof typeof BREAKPOINTS, Function>);
