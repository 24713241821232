/** @format */

export const getCityUrl = (name: string, locale: string, type: 'campus' | 'dv' | 'altido', country: string) => {
  switch (type) {
    case 'campus':
      return getCampusUrl(name, locale);
    case 'dv':
      return getDVUrl(name, locale);
    case 'altido':
      return getAltidoUrl(name, country, locale);
    default:
      throw new Error('Invalid city type');
  }
};

const getDVUrl = (name: string, locale: string) => {
  // TODO: add french when active
  const baseurl =
    locale === 'it' ? 'https://www.dovevivo.it/it/affitto-stanza-' : 'https://www.dovevivo.com/en/rent-room-';

  return `${baseurl}${getCityName(name, locale)}/`;
};

const getCampusUrl = (name: string, locale: string) => {
  const baseurl = locale === 'it' ? 'https://campus.dovevivo.com/' : 'https://campus.dovevivo.com/en/';
  const cityname = name === 'como' ? 'como-campus' : getCityName(name, locale);
  return `${baseurl}${cityname}/`;
};

const getAltidoUrl = (name: string, country: string, locale: string) => {
  const isCountry = country === name;
  const baseurl = 'https://www.stayaltido.com/book/step1?';

  if (isCountry) return `${baseurl}country=${country !== 'en' ? country : 'eng'}&lang=${locale === 'it' ? 'it' : 'en'}`;
  return `${baseurl}omnibar=district%3b${name}&lang=${locale === 'it' ? 'it' : 'en'}`;
};

// TODO: refactor
const getCityName = (name: string, locale: string): string => {
  switch (name) {
    case 'milan':
      if (locale === 'it') return 'milano';
      return 'milan';
    case 'florence':
      if (locale === 'it') return 'firenze';
      return 'florence';
    case 'padua':
      if (locale === 'it') return 'padova';
      return 'padua';
    case 'rome':
      if (locale === 'it') return 'roma';
      return 'rome';
    case 'turin':
      if (locale === 'it') return 'torino';
      return 'turin';
    case 'venice':
      if (locale === 'it') return 'venezia';
      return 'venice';
    case 'lyon':
      if (locale === 'it') return 'lione';
      return 'lyon';
    case 'paris':
      if (locale === 'it') return 'parigi';
      return 'paris';
    case 'toulouse':
      if (locale === 'it') return 'tolosa';
      return 'toulouse';
    default:
      return name;
  }
};

export const formatCountryFromISO = (country: string) => {
  switch (country) {
    case 'it':
      return 'italy';
    case 'en':
      return 'england';
    case 'es':
      return 'spain';
    case 'fr':
      return 'france';
    case 'pt':
      return 'portugal';
    case 'gb':
      return 'united_kingdom';
    case 'ie':
      return 'ireland';
    case 'sco':
      return 'scotland';
    default:
      throw new Error(`Invalid country code passed to formatCountry: ${country}`);
  }
};
