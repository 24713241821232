/** @format */

import { createContext, useContext } from 'react';

export type LanguageSwitcherType = {
  slugs: {
    [key: string]: string;
  };
  setSlugs: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
};

const initialValue: LanguageSwitcherType = {
  slugs: {},
  setSlugs: () => {},
};

export const LanguageSwitcherContext = createContext<LanguageSwitcherType>(initialValue);
export const useLanguageSwitcherContext = () => useContext(LanguageSwitcherContext);
