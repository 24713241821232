/** @format */

import { formatCountryFromISO } from '@/components/Landing/CitySelectionDropdown/utils';
import { GTMEvent } from '@/types/gtm';

declare global {
  interface Window {
    dataLayer: any;
  }
}

const isBrowser = typeof window !== 'undefined';

export const GTM_ID = 'GTM-WQD4Z2B';

export const pageview = (url: string) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

export const utilityGTMWsetup = (pageType: string, locale: string) => {
  if (!isBrowser) return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'wSetup',
    country: locale === 'en' ? 'gb' : locale,
    language: locale,
    currencyCode: 'EUR',
    pageType: pageType,
    marketingCookie: 0,
    brand: 'joivy',
  });
};

export const dispatchGTMEvent = (event: GTMEvent) => {
  if (!isBrowser) return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GA4event',
    ...event,
  });
};

export const GTMLandingHousingType = (type: 'coliving' | 'travel_and_corporate' | 'campus') => {
  const getLabel = () => {
    if (type === 'coliving') return 'explore_coliving_space';
    if (type === 'travel_and_corporate') return 'explore_travel_spaces';
    return 'explore_campus_spaces';
  };
  const event: GTMEvent = {
    eventName: 'housing_type_selection',
    type,
    label: getLabel(),
  };
  dispatchGTMEvent(event);
};

export const GTMDestinationSelection = (
  cityName: string,
  country: string,
  type: 'coliving' | 'travel_and_corporate',
) => {
  const formatLabel = () => (cityName === country ? `view_all_${formatCountryFromISO(country)}` : cityName);

  const event: GTMEvent = {
    eventName: 'city_selection',
    type,
    label: formatLabel(),
    position: formatCountryFromISO(country),
  };
  dispatchGTMEvent(event);
};

export const GTMSectionClick = (label: string) => {
  const event: GTMEvent = {
    eventName: 'menu',
    position: 'menu_section',
    label,
  };
  dispatchGTMEvent(event);
};

export const GTMLinkClick = (label: string, position?: 'menu_label' | 'menu_section') => {
  const event: GTMEvent = {
    eventName: 'menu',
    position: position ?? 'menu_label',
    label,
  };
  dispatchGTMEvent(event);
};

export const GTMChangeLanguageClick = (label: string, position: 'menu' | 'header' | 'footer') => {
  const event: GTMEvent = {
    eventName: 'change_language',
    position,
    label,
  };
  dispatchGTMEvent(event);
};

export const GTMHeaderClick = (label: string) => {
  const event: GTMEvent = {
    eventName: 'header',
    label,
  };

  dispatchGTMEvent(event);
};

export const GTMFooterClick = (label: string, type?: string) => {
  const event: GTMEvent = {
    eventName: 'footer',
    label,
  };

  if (type) {
    event.type = type;
  }

  dispatchGTMEvent(event);
};
export const GTMArticleClick = (title: string, category: string) => {
  const event: GTMEvent = {
    eventName: 'article',
    title: title,
    category: category,
  };
  dispatchGTMEvent(event);
};
export const GTMCTAClick = (type: string, label: string) => {
  const event: GTMEvent = {
    eventName: 'cta_click',
    type,
    label,
  };
  dispatchGTMEvent(event);
};
export const GTMBannerClick = (label: string) => {
  const event: GTMEvent = {
    eventName: 'banner_click',
    label,
  };
  dispatchGTMEvent(event);
};

export const GTMFormSubmit = (type: 'individual' | 'company') => {
  const event: GTMEvent = {
    eventName: 'generate_lead',
    type,
  };
  dispatchGTMEvent(event);
};
