/** @format */

// Check https://styled-components.com/docs/api#typescript

import { createGlobalStyle } from 'styled-components';
import { figtree, trirong, unbounded } from './fonts';
import { BREAKPOINTS } from './mixins';

// everything that's in this object must be added in styled.d.ts
const theme = {
  breakpoints: BREAKPOINTS,
  font: {
    title: `${unbounded.style.fontFamily}`,
    accent: `${trirong.style.fontFamily}`,
    body: `${figtree.style.fontFamily}`,
    size: {
      '0.6875': '0.6875rem',
      '0.9375': '0.9375rem',
      '1': '1rem',
      '1.125': '1.125rem',
      '1.25': '1.25rem',
      '1.375': '1.375rem',
      '1.4375': '1.4375rem',
      '1.5': '1.5rem',
      '1.75': '1.75rem',
      '2.0625': '2.0625rem',
      '2.375': '2.375rem',
      '2.8125': '2.8125rem',
      '3.125': '3.125rem',
      '3.3125': '3.3125rem',
      '3.5': '3.5rem',
      '4.375': '4.375rem',
      '4.875': '4.875rem',
      '6': '6rem',
      '7.1875': '7.1875rem',
    },
  },
  color: {
    black: '#000',
    white: '#fff',
    yellow: '#ffe84c',
    pink: '#E56E69',
    teal: {
      dark: '#015962',
      light: '#3aadb4',
    },
    gray: {
      dark: '#86939E',
      light: '#E1E8EE',
      transparent: '#00000029',
    },
    blue: '#043B5C',
    error: '#ED6942',
    accent: {
      purple: '#8F2457',
      green: '#21B096',
      orange: {
        dark: '#ED6942',
        light: '#F0A642',
      },
      gray: '#A6B8C4',
      pink: '#FACFC7',
    },
    landing: {
      dark: '#003338',
      darker: '#00272A',
      white: '#fafafa',
      gray: {
        light: '#EEEEEE',
        dark: '#DDDDDD',
      },
    },
  },
  spaces: {
    '0.94': '.94rem',
    1.25: '1.25rem',
    3: '3rem',
  },
  container: {
    defaultPadding: 'clamp(1.88rem, 0.5067rem + 6.1037vw, 6rem)',
    smallPadding: 'clamp(0.94rem, 0.2533rem + 3.0519vw, 3rem)',
  },
};

export default theme;

export const GlobalStyle = createGlobalStyle`

*, *::after, *::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;

}

body {
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${({ theme }) => `clamp(${theme.font.size[1]}, 0.9583rem + 0.1852vw, ${theme.font.size[1.125]});`};
  font-style: normal;
  font-weight: 400;
  line-height: clamp(1.375rem, 1.3333rem + 0.1852vw, 1.5rem);
  letter-spacing: clamp(0.03rem, 0.0287rem + 0.0056vw, 0.03375rem);
  color: ${({ theme }) => theme.color.teal.dark};
  background-color: ${({ theme }) => theme.color.white};


  &.fixed {
    overflow-y: hidden;
  }

}

#__next > div {
  display: flex;
  min-height: 100vh;
  min-height: 100dvh;
  flex-direction: column;
}

main {
  position: relative;
  flex: 1 1 auto;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.grecaptcha-badge{
  visibility:hidden
}

`;
