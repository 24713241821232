/** @format */

import { reactPlugin } from '@/lib/appinsights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { StyleSheetManager } from 'styled-components';
import { LanguageSwitcherProvider } from '../context/LanguageSwitcherContext';
import { GTM_ID } from '../lib/google-tag-manager';
import { ThemeProvider } from '../theme/Provider';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        async
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />

      <StyleSheetManager enableVendorPrefixes>
        <ThemeProvider>
          <LanguageSwitcherProvider>
            <Component {...pageProps} />
          </LanguageSwitcherProvider>
        </ThemeProvider>
      </StyleSheetManager>
    </>
  );
}

export default withAITracking(reactPlugin, MyApp);
