/** @format */

import { FunctionComponent as FC, ReactNode, memo } from 'react';

import { ThemeProvider as StyledProvider } from 'styled-components';

import theme, { GlobalStyle } from './styled-theme';

const ThemeProvider: FC<Props> = memo(({ children }) => (
  <StyledProvider theme={theme}>
    <GlobalStyle />
    {children}
  </StyledProvider>
));

type Props = {
  children: ReactNode;
};

ThemeProvider.displayName = 'ThemeProvider';

export { ThemeProvider };
